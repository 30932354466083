import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1ObjectDetectionPostRequest,
  ConduxApiResearcherV1ObjectDetectionStudyGetResponse,
  ConduxApiResearcherV1objectDetectionStudiesGetResponse,
  ConduxApiResearcherV1ObjectDetectionJobPostRequest,
  ConduxApiResearcherV1ObjectDetectionJobGetReponse,
  ConduxApiResearcherV1ObjectDetectionJobsGetReponse,
  ObjectDetectionStudy,
  StudyV2Helper,
} from '@conduxio/types'

export class StudyObjectDetectionService extends ConduxService {

  protected modelNameSingular = 'study-v2'
  protected modelNamePlural = 'studies-v2'

  public read(id: string, withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiResearcherV1ObjectDetectionStudyGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/object-detection/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include }

    const ret = super.get<ConduxApiResearcherV1ObjectDetectionStudyGetResponse>(apiRequest)

    return ret
  }

  public readAll(withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiResearcherV1objectDetectionStudiesGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/object-detection', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include }

    const ret = super.get<ConduxApiResearcherV1objectDetectionStudiesGetResponse>(apiRequest)

    return ret
  }
  
  public create(request: ConduxApiResearcherV1ObjectDetectionPostRequest, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1ObjectDetectionStudyGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/object-detection', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: request }

    return super.post<ConduxApiResearcherV1ObjectDetectionStudyGetResponse>(apiRequest)
  }

  public update(model: ObjectDetectionStudy, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1ObjectDetectionStudyGetResponse> {
    const validationResult = StudyV2Helper.validate(model)

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1ObjectDetectionStudyGetResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error updating Study, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1ObjectDetectionStudyGetResponse)
      })
    }

    const apiRequest = {url: this.modelNameSingular + '/object-detection/' + model.id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: { study: validationResult.study }, modelId: model.id }

    return super.put<ConduxApiResearcherV1ObjectDetectionStudyGetResponse>(apiRequest)
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: this.modelNameSingular + '/object-detection/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, modelId: id }

    return super.delete<ConduxApiCommonV1Response>(apiRequest)
  }

  public readJob(id: string, withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiResearcherV1ObjectDetectionJobGetReponse> {
    const apiRequest = {url: this.modelNameSingular + '/object-detection-job/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include }

    const ret = super.get<ConduxApiResearcherV1ObjectDetectionJobGetReponse>(apiRequest)

    return ret
  }

  public readAllJobs(withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiResearcherV1ObjectDetectionJobsGetReponse> {
    const apiRequest = {url: this.modelNameSingular + '/object-detection-job', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include }

    const ret = super.get<ConduxApiResearcherV1ObjectDetectionJobsGetReponse>(apiRequest)

    return ret
  }

  public createJob(request: ConduxApiResearcherV1ObjectDetectionJobPostRequest, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1ObjectDetectionJobsGetReponse> {
    const apiRequest = {url: this.modelNameSingular + '/object-detection-job', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: request }

    return super.post<ConduxApiResearcherV1ObjectDetectionJobsGetReponse>(apiRequest)
  }

  public removeJob(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: this.modelNameSingular + '/object-detection-job/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, modelId: id }

    return super.delete<ConduxApiCommonV1Response>(apiRequest)
  }
}
