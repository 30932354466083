import { ConduxService } from '@/services/condux.service'
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1SurveyStudyGetResponse,
  ConduxApiResearcherV1SurveyStudiesGetResponse,
  SurveyStudy,
  StudyV2Helper,
} from '@conduxio/types'

export class StudySurveyService extends ConduxService {

  protected modelNameSingular = 'study-v2'
  protected modelNamePlural = 'studies-v2'

  public read(id: string, withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiResearcherV1SurveyStudyGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/survey/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include }

    const ret = super.get<ConduxApiResearcherV1SurveyStudyGetResponse>(apiRequest)

    return ret
  }

  public readAll(withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiResearcherV1SurveyStudiesGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/survey', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include }

    const ret = super.get<ConduxApiResearcherV1SurveyStudiesGetResponse>(apiRequest)

    return ret
  }

  public create(model: SurveyStudy, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1SurveyStudyGetResponse> {
    const apiRequest = {url: this.modelNameSingular + '/survey', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: {study: model} }

    return super.post<ConduxApiResearcherV1SurveyStudyGetResponse>(apiRequest)
  }

  public update(model: SurveyStudy, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1SurveyStudyGetResponse> {
    const validationResult = StudyV2Helper.validate(model)

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SurveyStudyGetResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error updating Study, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1SurveyStudyGetResponse)
      })
    }

    const apiRequest = {url: this.modelNameSingular + '/survey/' + model.id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: { study: validationResult.study }, modelId: model.id }

    return super.put<ConduxApiResearcherV1SurveyStudyGetResponse>(apiRequest)
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {url: this.modelNameSingular + '/survey/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, modelId: id }

    return super.delete<ConduxApiCommonV1Response>(apiRequest)
  }

  // public readJob(id: string, withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiResearcherV1FaceSwapJobGetReponse> {
  //   const apiRequest = {url: this.modelNameSingular + '/survey-job/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include }

  //   const ret = super.get<ConduxApiResearcherV1FaceSwapJobGetReponse>(apiRequest)

  //   return ret
  // }

  // public readAllJobs(withoutWorkspaceId = false, workspaceId = '', include = ''): Promise<ConduxApiResearcherV1FaceSwapJobsGetReponse> {
  //   const apiRequest = {url: this.modelNameSingular + '/survey-job', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, include:include }

  //   const ret = super.get<ConduxApiResearcherV1FaceSwapJobsGetReponse>(apiRequest)

  //   return ret
  // }

  // public createJob(request: ConduxApiResearcherV1FaceSwapJobPostRequest, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiResearcherV1FaceSwapJobsGetReponse> {
  //   const apiRequest = {url: this.modelNameSingular + '/survey-job', withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, object: request }

  //   return super.post<ConduxApiResearcherV1FaceSwapJobsGetReponse>(apiRequest)
  // }

  // public removeJob(id: string, withoutWorkspaceId = false, workspaceId = ''): Promise<ConduxApiCommonV1Response> {
  //   const apiRequest = {url: this.modelNameSingular + '/survey-job/' + id, withoutWorkspaceId:withoutWorkspaceId, workspaceId:workspaceId, modelId: id }

  //   return super.delete<ConduxApiCommonV1Response>(apiRequest)
  // }
}
