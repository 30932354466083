<template>
  <v-app class="condux b-light_gray" :theme="$globalsService.theme" full-height>
    <template v-if="$route.meta.emptyLayout">
      <router-view/>
    </template>
    <MainLayout v-else>
      <router-view/>
    </MainLayout>
    <!-- #region Components -->
    <message-box/>       <!-- MessageBox -->

    <notifications 
      position="bottom right" 
      :duration="10000" 
      :speed="1000" 
      :pause-on-hover="true" 
      :width="$vuetify.display.xs? '100%': '400px'"
      class="mb-3 pb-3"
    >
      <template #body="props">
        <v-card :data-cy="`notification-${props.item.type}`" class="my-notification" :class="props.item.type">
          <v-card-title>
            <v-icon v-if="props.item.type === 'info'" size="x-small" class="mr-1">{{ $icons.bell }}</v-icon>
            <span class="notification_title" v-html="props.item.title"></span>
            <v-spacer/>
            <button class="close" @click="closeNotification(props.item.id)">
              <v-icon size="x-small">{{ $icons.close }}</v-icon>
            </button>
          </v-card-title>
          <v-card-text class="text-subtitle-1">
            <span v-html="props.item.text"></span>
          </v-card-text>
        </v-card>
      </template>
    </notifications>
  </v-app>
</template>

<script lang="ts">
import { ConduxService } from '@/services/condux.service'
import { OauthAccountService } from '@/services/oauth-account.service'
import { ProjectService } from '@/services/project.service'
import { SurveyService } from '@/services/survey.service'
import { ResearcherService, setResearcherServiceInstance } from '@/services/researcher.service'
import { ScheduleService } from '@/services/schedule.service'
import { SessionService } from '@/services/session.service'
import { StudyService } from '@/services/study.service'
import { StudyV2Service } from '@/services/studyV2.service'
import { StudyFaceSwapService } from '@/services/study-face-swap.service'
import { StudySurveyService } from '@/services/study-survey.service'
import { StudyObjectDetectionService } from '@/services/study-object-detection.service'
import { SessionCommentService } from '@/services/session-comment.service'
import { SessionTagService } from '@/services/session-tag.service'
import { SessionPlaybackService } from '@/services/session-playback.service'
import { SessionPlaybackDownloadService } from '@/services/session-playback-download.service'
import { SessionParticipantService } from '@/services/session-participant.service'
import { RoleService } from '@/services/role.service'
import { FileService } from '@/services/file.service'
import { RegisterService } from '@/services/register.service'
import { WorkspaceService } from '@/services/workspace.service'
import { TemplateService } from '@/services/template.service'
import { FMPService } from '@/services/FMP.service'
import { DashboardService } from '@/services/dashboard.service'
import { LocalizationService } from '@/services/localization.service'
import { defineComponent, ref } from 'vue'
import { useMeta } from 'vue-meta'
import MainLayout from '@/layouts/MainLayout.vue'
import { globalInstance } from './services/globals.service'
// Imports Components
import MessageBox from './components/notifications/message-box.vue'
//Push notification service
import { setOnMessageEvent } from '@/pwa/firebase'

const researcherServiceInstance = new ResearcherService(globalInstance)

setResearcherServiceInstance(researcherServiceInstance)

export default defineComponent({
  name: 'App',
  components: {
    MainLayout,
    // Components
    MessageBox,
  },
  provide: {
    conduxService: ref(new ConduxService(globalInstance)),
    oauthAccountService: ref(new OauthAccountService(globalInstance)),
    projectService: ref(new ProjectService(globalInstance)),
    surveyService: ref(new SurveyService(globalInstance)),
    researcherService: ref(researcherServiceInstance),
    scheduleService: ref(new ScheduleService(globalInstance)),
    sessionService: ref(new SessionService(globalInstance)),
    studyService: ref(new StudyService(globalInstance)),
    studyV2Service: ref(new StudyV2Service(globalInstance)),
    studyFaceSwapService: ref(new StudyFaceSwapService(globalInstance)),
    studySurveyService: ref(new StudySurveyService(globalInstance)),
    studyObjectDetectionService: ref(new StudyObjectDetectionService(globalInstance)),
    sessionCommentService: ref(new SessionCommentService(globalInstance)),
    sessionTagService: ref(new SessionTagService(globalInstance)),
    sessionPlaybackService: ref(new SessionPlaybackService(globalInstance)),
    sessionPlaybackDownloadService: ref(new SessionPlaybackDownloadService(globalInstance)),
    sessionParticipantService: ref(new SessionParticipantService(globalInstance)),
    roleService: ref(new RoleService(globalInstance)),
    fileService: ref(new FileService(globalInstance)),
    templateService: ref(new TemplateService(globalInstance)),
    fMPService: ref(new FMPService(globalInstance)),
    dashboardService: ref(new DashboardService(globalInstance)),
    localizationService:ref(new LocalizationService(globalInstance)),
    workspaceService: ref(new WorkspaceService(globalInstance)),
    registerService: ref(new RegisterService(globalInstance)),
  },

  setup() {
    useMeta({
      title: 'Condux',
      description: 'Recruit, qualify and pay high-quality research participants for any type of research study. Manage non-customer and customer participants for quantitative and qualitative research studies.',
      htmlAttrs: { lang: 'en', amp: true },
    })

    return {
      
    }
  },
  data() {
    return {
      isActive: true,
    }
  },
  created() {
    document.addEventListener("visibilitychange",() => {
      this.isActive = !document.hidden
    });
    window.addEventListener('offline', () => {
      this.isActive = false
    })
    window.addEventListener('online', () => {
      this.isActive = !document.hidden
    })
    setOnMessageEvent()
  },    
  watch: {  
  },
  methods: {
    closeNotification(id) {
      this.$notify.close(id)
    },
  },
})
</script>

<style lang="scss" scoped>
.my-notification {
  margin-top: 5px;
  &.success {
    border-left: 5px solid green;
  }

  &.warn {
    border-left: 5px solid rgb(220, 220, 75);
  }

  &.error {
    border-left: 5px solid red;
  }
  &.info {
    border-left: 5px solid rgb(39, 39, 234);
  }

  span.notification_title {
    margin-top: -5px;
    display: inline-block;
  }
}
</style>